import React, { ReactElement } from "react";
import styles from "./styles.module.scss";
import BottomImage from "../bottomImage";

export default function Benefits(): ReactElement {
    return (
        <div
            className={`flex flex-col xl:flex-row flex-nowrap items-center justify-between mb-28 mt-12 ${styles["DoYouKnowWrapper"]}`}
        >
            <div className="w-full md:w-3/5 ">
                <div
                    className={` font-bold mb-8  text-3xl xl:text-4xl pt-11 md:pt-12  ${styles["title"]}`}
                >
                    What Is Tax Debt?
                </div>
                <p className={` text-base lg:text-lg mb-6 ${styles["brief"]}`}>
                    If you miss paying or filing your taxes, make a mistake on
                    your tax return, or the IRS changes your taxes, and you owe
                    money, you have tax debt. It's a common issue — in 2017,
                    over 800,000 Americans had tax debt. Don't worry, though;
                    the IRS offers ways to fix this. You can sort out your tax
                    debt by correcting your tax return or setting up a payment
                    plan or a deal with the IRS to pay less. These options can
                    help reduce or even clear what you owe.
                </p>
            </div>
            <BottomImage />
        </div>
    );
}
