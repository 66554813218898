import React, { ReactElement } from "react";
import styles from "./styles.module.scss";
import Image from "next/image";
export default function BottomImage(): ReactElement {
    return (
        <div className={`w-full md:w-3/5 ${styles["mainBannerWrapper"]}`}>
            <Image
                src="/assets/images/sideImage.svg"
                className={styles["background-img"]}
                alt="TaxDebtOffers - What is tax debt?"
                layout="intrinsic"
                priority
                width={546}
                height={450}
            />
        </div>
    );
}
